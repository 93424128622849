<template>
  <div :class="`page ${currentDirectory.slug}`">
    <ais-configure :distinct="true" />

    <SearchHero />
    <div class="columns is-multiline is-desktop">
      <FeaturedPrimaryBanner />
      <SearchGrid class="column is-12-desktop" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchGrid from '@/components/SearchGrid.vue';
import SearchHero from '@/components/Global/SearchHero.vue';
import FeaturedPrimaryBanner from '@/components/InstantSearch/FeaturedPrimaryBanner.vue';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
  },
  methods: {
    resetPage() {
      window.scrollTo(0, 0);
      // Remove all highlighted elements
      const allResults = document.querySelectorAll('.ais-hits a.media');
      allResults.forEach((result) => result.classList.remove('has-background-secondary-lighter'));
    },
  },
  mounted() {
    this.$store.dispatch('advertisements/get');
  },
  components: {
    SearchGrid,
    SearchHero,
    FeaturedPrimaryBanner,
  },
};
</script>
