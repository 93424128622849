<template>
  <!-- TODO: The grid view of the list. Make this an option instead of a route -->
  <div
    id="search-results"
    :style="{ 'margin-top': offset }"
    class="grid"
  >
    <ActiveRefinements />
    <ais-configure :hits-per-page.camel="80" />

    <ais-infinite-hits
      :class-names="{
        'ais-InfiniteHits-loadMore': 'loadResults',
        'ais-InfiniteHits-loadPrevious': 'loadResults',
        'ais-InfiniteHits-list': 'columns is-multiline',
        'ais-InfiniteHits-item': 'column is-4',
      }"
      :transform-items="transform"
    >
      <template slot="item" slot-scope="{ item, index }">
        <Ad
          :key="`ResultsAd-${index}`"
          ad-slot="ResultsAd"
          :index="index"
        />
        <router-link
          :id="item.objectID"
          :to="{ name: 'record', params: { record: item.slug }}"
          :class="`${ item.sponsor ? 'is-featured' : '' }`"
          :aria-label="item.name"
        >
          <FeaturedBadge :sponsored="item.sponsor" />
          <ExpertBadge :expert="item.expert" />
          <RecordImage :image-uri="item['image-uri']" :name="item.name" />
          <div class="content">
            <h4 class="title is-size-4 is-marginless">
              <ais-highlight :hit="item" attribute="name" />
              <RecordPrimaryFilterLabel
                class="is-size-7 is-primary-filter-label"
                :class="{ 'pl-5': isFilteredView && item.rank !== 9999 }"
                :record="item"
              />
            </h4>
            <RecordDetailSnapshot v-if="item.sponsor || isFilteredView" :record="item" />
          </div>
        </router-link>
      </template>
    </ais-infinite-hits>
    <NoResults />
  </div>
</template>

<script>
/* eslint-disable */
import arrays from '@/mixins/arrays';
import {mapState, mapGetters} from 'vuex';
import {createInfiniteHitsSessionStorageCache} from 'instantsearch.js/es/lib/infiniteHitsCache';

export default {
  data() {
    return {
      cache: createInfiniteHitsSessionStorageCache(),
      offset: '0px',
    };
  },
  mixins: [arrays],
  computed: {
    ...mapState('advertisements', {
      results_ads: (state) => state.results_ads,
      ad_provider: (state) => state.provider,
      hasBanner: (state) => state.hasBanner,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    isFilteredView() {
      return Object.keys(this.$route.query).includes(this.currentDirectory.primary_filter.field);
    },
  },
  methods: {
    calculateOffset() {
      setTimeout(() => {
        //TODO: move to a mixin
        const offset = Math.max(
            document.getElementById('featuredPrimaryBanner')?.offsetHeight,
            0
        ) + 20;
        this.offset = `${offset}px`;
      }, 200);
      setTimeout(() => {
        //TODO: move to a mixin
        const offset = Math.max(
            document.getElementById('featuredPrimaryBanner')?.offsetHeight,
            0
        ) + 20;
        this.offset = `${offset}px`;
      }, 500);
      setTimeout(() => {
        //TODO: move to a mixin
        const offset = Math.max(
            document.getElementById('featuredPrimaryBanner')?.offsetHeight,
            0
        ) + 20;
        this.offset = `${offset}px`;
      }, 1000);
    },
    getRank(record, currentFilter) {
      //TODO: extract to mixin
      // Gets only the FIRST key element for the field.
      // For example, `lists.key` is the primary filter, but we only want to get `lists`
      // as the field.
      const recordField = record[this.currentDirectory.primary_filter.field.split('.')[0]];

      // Change `list` to `key` when records in Algolia are updated
      const validRecord = recordField?.find((field) => field?.key === currentFilter);

      // Return a "rank" of super high so it shows at the bottom of the list temporarily
      // before it's hidden by the facet filter again.
      return validRecord?.rank ?? 9999;
    },
    transform(items) {
      //TODO: extract to mixin
      const filter = this.$router.currentRoute.query?.[this.currentDirectory.primary_filter.field];

      if (filter === undefined) {
        const splitItems = this.groupBy(items, 'sponsor');
        return [...this.shuffle(splitItems[true]), ...splitItems[false]];
      }

      return items
          .sort((one, two) => (this.getRank(one, filter) - this.getRank(two, filter)))
          .map((item) => ({...item, rank: this.getRank(item, filter)}));
    },
  },
  watch: {
    hasBanner() {
      this.calculateOffset();
    },
  },
  created() {
    window.addEventListener('resize', this.calculateOffset);
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateOffset);
  },
  mounted() {
    this.calculateOffset();
  },
  components: {
    ActiveRefinements: () => import('@/components/InstantSearch/ActiveRefinements.vue'),
    Ad: () => import('@/components/Ads/Ad.vue'),
    FeaturedBadge: () => import('@/components/Badges/Featured.vue'),
    ExpertBadge: () => import('@/components/Badges/Expert.vue'),
    RecordImage: () => import('@/components/SearchResults/RecordImage.vue'),
    RecordRank: () => import('@/components/InstantSearch/RecordRank.vue'),
    RecordDetailSnapshot: () => import('@/components/SearchResults/RecordDetailSnapshot.vue'),
    RecordPrimaryFilterLabel: () => import('@/components/SearchResults/RecordPrimaryFilterLabel.vue'),
    NoResults: () => import('@/components/InstantSearch/NoResults.vue'),
  },
};
</script>
