var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid",style:({ 'margin-top': _vm.offset }),attrs:{"id":"search-results"}},[_c('ActiveRefinements'),_c('ais-configure',{attrs:{"hitsPerPage":80}}),_c('ais-infinite-hits',{attrs:{"class-names":{
      'ais-InfiniteHits-loadMore': 'loadResults',
      'ais-InfiniteHits-loadPrevious': 'loadResults',
      'ais-InfiniteHits-list': 'columns is-multiline',
      'ais-InfiniteHits-item': 'column is-4',
    },"transform-items":_vm.transform},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('Ad',{key:("ResultsAd-" + index),attrs:{"ad-slot":"ResultsAd","index":index}}),_c('router-link',{class:("" + (item.sponsor ? 'is-featured' : '')),attrs:{"id":item.objectID,"to":{ name: 'record', params: { record: item.slug }},"aria-label":item.name}},[_c('FeaturedBadge',{attrs:{"sponsored":item.sponsor}}),_c('ExpertBadge',{attrs:{"expert":item.expert}}),_c('RecordImage',{attrs:{"image-uri":item['image-uri'],"name":item.name}}),_c('div',{staticClass:"content"},[_c('h4',{staticClass:"title is-size-4 is-marginless"},[_c('ais-highlight',{attrs:{"hit":item,"attribute":"name"}}),_c('RecordPrimaryFilterLabel',{staticClass:"is-size-7 is-primary-filter-label",class:{ 'pl-5': _vm.isFilteredView && item.rank !== 9999 },attrs:{"record":item}})],1),(item.sponsor || _vm.isFilteredView)?_c('RecordDetailSnapshot',{attrs:{"record":item}}):_vm._e()],1)],1)]}}])}),_c('NoResults')],1)}
var staticRenderFns = []

export { render, staticRenderFns }